import React, { useEffect, Suspense, lazy, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Video from "../../images/output.webm";
import Footer from "../../components/footer";
import Header from "../../components/navigation-header";

const Card = lazy(() => import("./cardSlidesShow"));
const Values = lazy(() => import("./values"));
const Homeslideshow = lazy(() => import("./mobile-home-slideshow"));
const HomeCard = lazy(() => import("./home-card"));

export default function Home() {
  return (
    <div>
      <div className="home-container">
        <Header />
        <div className="section1 d-flex row m-0 p-0">
          <div className="col section-1-left section-1-child">
            <div className="grid-text left-text">
              <h2 className="first-name">Building quality</h2>
              <p className="second-name">improving life.</p>
            </div>
            <div className="logo-container">
              <a href="https://www.beirabrush.com/" target="_blank">
                <img
                  style={{ cursor: "pointer" }}
                  src="/images/HOME/Beira-Brush-logo-Final.webp"
                  loading="eager"
                  alt="logo"
                />
              </a>
              <a href="https://www.ecospindles.com/" target="_blank">
                <img
                  loading="eager"
                  style={{ cursor: "pointer" }}
                  src="/images/HOME/Es-logo-Final.webp"
                  alt="ecospindles-logo"
                />
              </a>
            </div>
          </div>
          <div className="col section-1-right section-1-child">
            <div className="grid-text right-text">
              <h2 className="first-name">Building quality</h2>
              <p className="second-name">improving life.</p>
            </div>
            <div
              className="video-container"
              style={{
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <video
                id="v"
                className="home-video"
                autoPlay
                muted
                loop
                preload="metadata"
                aria-label="video"
              >
                <source src={Video} type="video/webm" />
              </video>
            </div>
          </div>
        </div>

        <div
          className="section2"
          style={{
            textAlign: "left",
            width: "100%",
            backgroundColor: "#1b1464",
          }}
        >
          <center>
            <div className="section-2-para">
              <br />
              <span>
                Through partnerships, innovation, solid governance, and
                environmental stewardship, we are dedicated to creating
                sustainable futures for society. We balance achievement and
                obligation and are committed to the well-being of the population
                and the environment.
              </span>
            </div>

            <div className="section2-box">
              <Suspense fallback={<span></span>}>
                <HomeCard />
              </Suspense>
            </div>
          </center>
        </div>

        <Suspense fallback={<span></span>}>
          <Homeslideshow />
        </Suspense>

        <div style={{ marginTop: "100px", marginBottom: "30px" }}>
          <h2 className="latest-press-releases">Latest press releases</h2>
          <Suspense fallback={<span></span>}>
            <Card />
          </Suspense>
        </div>
        <Suspense fallback={<span></span>}>
          <Values />
        </Suspense>
        <Footer />
      </div>
    </div>
  );
}
