import React, { Suspense, lazy } from "react";
import HomePage from "../templates/home/home";
import { Helmet } from "react-helmet";
import {
  MetaTitle,
  MetaDescription,
  MetaKeywords,
  MetaImage,
  MetaOGTitle,
  MetaCanonical,
  MetaType,
  MetaOGDescription,
  MetaOGSiteName,
} from "../components/meta-tags";
import "../styling/component.css";
import "../styling/pages.css";
import "../styling/templates.css";
import "../components/sitemap.css";
import "../components/CookieNotice.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../components/CookieNotice.css";
// import CookiesNotice from "../components/CookieNotice";
const CookieNotice = lazy(() => import("../components/CookieNotice"));

export default function Index() {
  const title = MetaTitle();
  const description = MetaDescription();
  const metaKeywords = MetaKeywords();
  const metaImage = MetaImage();
  const metaOGTitle = MetaOGTitle();
  const metaCanonical = MetaCanonical();
  const metaType = MetaType();
  const metaOGDescription = MetaOGDescription();
  const metaOGSiteName = MetaOGSiteName();

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
        <link rel="canonical" href={metaCanonical} />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          rel="preload"
          href="https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap"
          as="style"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content={metaType} />
        <meta name="keywords" content={metaKeywords} />
        <meta property="og:image" content={metaImage} />
        <meta property="og:title" content={metaOGTitle} />
        <meta property="og:description" content={metaOGDescription} />
        <meta property="og:url" content={metaCanonical} />
        <meta property="og:site_name" content={metaOGSiteName} />
      </Helmet>
      <HomePage />
      <Suspense fallback={<></>}>
        <CookieNotice />
      </Suspense>
    </div>
  );
}
